import React from 'react';

const Backdrop = props => {
  return <div style={styles.backdrop} onClick={props.click} />;
};

const styles = {
  backdrop: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,0.3)',
    zIndex: 99,
  },
};

export default Backdrop;

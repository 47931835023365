import React, { Component } from 'react';
import { Container, Grid, Icon, Button } from 'semantic-ui-react';

class Footer extends Component {
  render() {
    return (
      <Container
        style={{
          flex: 1,
          backgroundColor: 'rgb(35, 33, 48)',
          width: '100%',
          marginTop: 30,
        }}
      >
        <Grid stackable style={{display:'flex', verticalAlign:'middle', justifyContent: 'middle' }}>
          <Grid.Column textAlign='center'>
            <Button onClick={() => window.location.href='https://www.google.com/maps/place/Smoothie+Mania/@36.8488032,-76.2913406,17z/data=!3m2!4b1!5s0x89ba980e5a6d8fe3:0x663c92cc41352156!4m5!3m4!1s0x89ba980dc43f6a07:0x450b48ca6b8c7c42!8m2!3d36.8487989!4d-76.2891466'} icon labelPosition='left'>
              <Icon name='location arrow' />
              300 Monticello Ave # 260, Norfolk, VA 23510
            </Button>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default Footer;

import React, { Component, Fragment } from 'react';
import {
  Container,
  Grid,
  Message,
  Header,
  Icon,
  Segment,
  Item,
  Image,
} from 'semantic-ui-react';

import ContactForm from '../Common/ContactForm';

class ContactPage extends Component {
  render() {
    return (
      <Fragment>
        <Container
          style={{
            width: '100%',
            margin: 0,
            flexBasis: 'auto',
          }}
        >
          <Grid columns="equal" style={{ width: '100%', margin: 0 }} stackable>
            <Grid.Column>
              <iframe
                title="smoothie mania map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.735794015539!2d-76.29133528435155!3d36.84880317266127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ba980dc43f6a07%3A0x450b48ca6b8c7c42!2sSmoothie+Mania!5e0!3m2!1sen!2sus!4v1550120856488"
                width="100%"
                height="515"
                frameBorder="0"
                style={{ border: '0' }}
                allowFullScreen
              >
                <a href="https://www.maps.ie/map-my-route/">Draw map route</a>
              </iframe>
            </Grid.Column>
            <Grid.Column>
              <Message>
                <Header as="h3"><em>Let us know your thoughts...</em></Header>
                <br />
                <ContactForm name="contact-form" method="post" action="" />
              </Message>
            </Grid.Column>
          </Grid>
        </Container>

        <Grid style={{ width: '100%', padding: '1rem', margin: 0 }} stackable>
          <Grid.Column>
            <Segment>
              <Grid columns={2} stackable>
                <Grid.Column stretched>
                  <Grid.Row>
                    <Header as="h2" style={{ padding: '1rem' }}>
                      <Icon name="plug" />
                      <Header.Content>Hours of Operation</Header.Content>
                    </Header>
                  </Grid.Row>
                  <Grid.Row>
                    <Item.Group divided style={{ marginLeft: '2rem' }}>
                      <Item>
                        <Item.Content verticalAlign="middle">
                          <b>Monday</b> <em> 10AM - 9:00PM</em>
                        </Item.Content>
                      </Item>
                      <Item>
                        <Item.Content verticalAlign="middle">
                          <b>Tuesday</b> <em> 10AM - 9:00PM</em>
                        </Item.Content>
                      </Item>
                      <Item>
                        <Item.Content verticalAlign="middle">
                          <b>Wednesday</b> <em> 10AM - 9:00PM</em>
                        </Item.Content>
                      </Item>
                      <Item>
                        <Item.Content verticalAlign="middle">
                          <b>Thursday</b> <em> 10AM - 9:00PM</em>
                        </Item.Content>
                      </Item>
                      <Item>
                        <Item.Content verticalAlign="middle">
                          <b>Friday</b> <em> 10AM - 9:00PM</em>
                        </Item.Content>
                      </Item>
                      <Item>
                        <Item.Content verticalAlign="middle">
                          <b>Saturday</b> <em> 10AM - 9:00PM</em>
                        </Item.Content>
                      </Item>
                      <Item>
                        <Item.Content verticalAlign="middle">
                          <b>Sunday</b> <em> 12PM - 6:00PM</em>
                        </Item.Content>
                      </Item>
                    </Item.Group>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                  <Segment style={{ backgroundColor: '#f4ece2' }}>
                    <Image
                      src="http://www.thesmoothiemania.com/imgs/ownerPicture.jpg"
                      size="large"
                      centered
                      rounded
                      bordered
                    />
                  </Segment>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

export default ContactPage;

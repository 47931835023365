import React, { Component, Fragment } from 'react';
import {
  Container,
  Grid,
  Segment,
  Divider,
  Reveal,
  Image,
  Header,
  Card,
} from 'semantic-ui-react';
import SmoothieList from '../Common/SmoothieList';

class SmoothiePage extends Component {
  render() {
    return (
      <Fragment>
        <Container
          style={{
            display: 'flex',
            backgroundColor: 'rgb(35, 33, 48)',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%',
            width: '100%',
            margin: 0,
          }}
        >
          <Segment style={{ flex: 1, backgroundColor: 'rgb(35, 33, 48)' }}>
            <Grid columns={2} centered stackable>
              <Divider style={{ color: 'white' }} vertical>
                Tasty
              </Divider>

              <Grid.Row>
                <Grid.Column
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Reveal animated="move">
                    <Reveal.Content visible>
                      <Image
                        label={{
                          as: 'a',
                          color: 'grey',
                          content: 'Strawberry Blossom Cheesecake',
                          ribbon: true,
                        }}
                        src="http://www.thesmoothiemania.com/imgs/strawberryCheesecake.jpg"
                        size="medium"
                      />
                    </Reveal.Content>
                    <Reveal.Content hidden>
                      <Card raised color="green">
                        <Image
                          style={{ padding: '0.5rem 0' }}
                          src="http://www.thesmoothiemania.com/imgs/strawberryCheesecake.jpg"
                          size="tiny"
                          centered
                        />
                        <Card.Content>
                          <Card.Header>
                            Strawberry Blossom Cheesecake
                          </Card.Header>
                          <Card.Meta>Strawberry</Card.Meta>
                          <Card.Meta>Cheesecake</Card.Meta>
                          <Card.Meta>Spinach</Card.Meta>
                          <Card.Meta>Kale</Card.Meta>
                          <Card.Meta>Protein</Card.Meta>
                          <Card.Description>
                            One of our most popular smoothies. Divine taste so
                            refreshing and healthy we guarantee you'll enjoy
                            this delicacy. Spinach | Kale | Protein combination
                            to recharge your powerhouse.
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    </Reveal.Content>
                  </Reveal>
                </Grid.Column>

                <Grid.Column
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Grid.Row style={{ justifyContent: 'flex-start' }}>
                    <Header
                      style={{
                        color: 'white',
                        fontFamily: 'Roboto Mono, monospace',
                        padding: '2rem',
                        marginLeft: '2rem',
                      }}
                      as="h4"
                    >
                      <span>
                        "We{' '}
                        <span style={{ fontWeight: '300', fontSize: '2rem' }}>
                          tirelessly
                        </span>{' '}
                        work hard...
                      </span>
                    </Header>
                  </Grid.Row>
                  <Grid.Row style={{ justifyContent: 'center' }}>
                    <Header
                      style={{
                        color: 'white',
                        fontFamily: 'Roboto Mono, monospace',
                        padding: '2rem',
                        marginLeft: '6rem',
                      }}
                      as="h4"
                    >
                      <span style={{ margin: '2rem 0' }}>
                        ...to create{' '}
                        <span style={{ fontWeight: '300', fontSize: '2rem' }}>
                          fresh,
                        </span>{' '}
                        tasty smoothies...
                      </span>
                    </Header>
                  </Grid.Row>
                  <Grid.Row style={{ justifyContent: 'flex-end' }}>
                    <Header
                      style={{
                        color: 'white',
                        fontFamily: 'Roboto Mono, monospace',
                        padding: '2rem',
                        marginLeft: '10rem',
                      }}
                      as="h4"
                    >
                      <span>...with the best ingredients"</span>
                    </Header>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
        <SmoothieList />
      </Fragment>
    );
  }
}

export default SmoothiePage;

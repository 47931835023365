import React from 'react';
import { Icon, Menu, Header, Image } from 'semantic-ui-react';
import history from '../history';

class SideDrawer extends React.Component {
  state = { activeItem: '' };

  // setting active menu item
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    history.push(`/${name}`);
  };

  render() {
    // destructure from state activeItem
    const { activeItem } = this.state;
    let drawerClasses = sideDrawer;

    if(this.props.show) {
      drawerClasses = sideDrawerOpen;
    }

    return (
      <nav style={drawerClasses}>
        <Menu
          icon="labeled"
          style={{
            height: '100%',
            width: '100%',
            margin: 0,
            justifyContent: 'center',
          }}
          vertical
        >
          <Header
            as="h1"
            style={{
              fontFamily: 'Aladin, cursive',
              marginBottom: 0,
              marginTop: 5,
              alignItems: 'center',
            }}
          >
            <Image
              className="toolbar_logo"
              style={{ margin: 0 }}
              circular
              src={require('../../img/smoothiemanie.jpg')}
            />{' '}
            Smoothie Mania
          </Header>
          <Menu.Item
            style={{ padding: '2rem' }}
            name=""
            active={activeItem === ''}
            onClick={this.handleItemClick}
          >
            <Icon name="food" />
            Home
          </Menu.Item>

          <Menu.Item
            style={{ padding: '2rem' }}
            name="Smoothies"
            active={activeItem === 'Smoothies'}
            onClick={this.handleItemClick}
          >
            <Icon name="star" />
            Smoothies
          </Menu.Item>

          <Menu.Item
            style={{ padding: '2rem' }}
            name="Contact"
            active={activeItem === 'Contact'}
            onClick={this.handleItemClick}
          >
            <Icon name="map marker" />
            Contact
          </Menu.Item>

          <Menu.Item
            style={{ padding: '2rem' }}
            name="About"
            active={activeItem === 'About'}
            onClick={this.handleItemClick}
          >
            <Icon name="smile" />
            About
          </Menu.Item>
        </Menu>
      </nav>
    );
  }
}

const sideDrawer = {
  height: '100%',
  background: 'white',
  boxShadow: '2px 0px 7px rgba(0,0,0,0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '70%',
  maxWidth: '400px',
  zIndex: 100,
  transform: 'translateX(-100%)',
  transition: 'transform 0.3s ease-out'
};

const sideDrawerOpen = {
  height: '100%',
  background: 'white',
  boxShadow: '2px 0px 7px rgba(0,0,0,0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '70%',
  maxWidth: '400px',
  zIndex: 100,
  transform: 'translateX(0)',
  transition: 'transform 0.3s ease-out'
};

export default SideDrawer;

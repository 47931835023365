import React, { Component, Fragment } from 'react';
import { Icon, Menu, Header, Image } from 'semantic-ui-react';
import history from '../history';

import './AppBar.css';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';

class AppBar extends Component {
  state = { activeItem: 'food' };

  // setting active menu item
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    history.push(`/${name}`);
  };

  render() {
    // destructure from state activeItem
    const { activeItem } = this.state;

    return (
      <Fragment>
        <Menu
          className="toolbar_navigation"
          icon="labeled"
          style={{ margin: 0 }}
        >
          <div className='toggleButton'>
            <DrawerToggleButton click={this.props.drawerClickHandler} />
          </div>
          <Header
            as="h1"
            style={{
              fontFamily: 'Aladin, cursive',
              marginBottom: 0,
              marginTop: 5,
              alignItems: 'center',
            }}
          >
            <Image
              className="toolbar_logo"
              style={{ margin: 0 }}
              circular
              src={require('../../img/smoothiemanie.jpg')}
            />
            <span className='main'>Smoothie Mania</span>
          </Header>
          <Menu.Item
            className='main'
            name=""
            active={activeItem === ''}
            onClick={this.handleItemClick}
            position="right"
          >
            <Icon name="food" />
            Home
          </Menu.Item>

          <Menu.Item
            className='main'
            name="Smoothies"
            active={activeItem === 'Smoothies'}
            onClick={this.handleItemClick}
          >
            <Icon name="star" />
            Smoothies
          </Menu.Item>

          <Menu.Item
            className='main'
            name="Contact"
            active={activeItem === 'Contact'}
            onClick={this.handleItemClick}
          >
            <Icon name="map marker" />
            Contact
          </Menu.Item>

          <Menu.Item
            className='main'
            name="About"
            active={activeItem === 'About'}
            onClick={this.handleItemClick}
          >
            <Icon name="smile" />
            About
          </Menu.Item>
        </Menu>
      </Fragment>
    );
  }
}

export default AppBar;

import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

class Input extends Component {
  render() {
    return (
      <Form.Field>
        <label htmlFor={this.props.name}>{this.props.name} *</label>
        <input
          type={this.props.type}
          name={this.props.name}
          required={this.props.required}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <span>{this.props.error}</span>
      </Form.Field>
    );
  }
}

export default Input;

import React, { Component, Fragment } from 'react';
import { Segment, Image } from 'semantic-ui-react';
// import { Player } from 'video-react';

class AboutPage extends Component {
  render() {
    return (
      <Fragment>
        {/* <Player
          playsInline
          poster="/assets/poster.png"
          src="https://www.youtube.com/watch?v=YuDhbLQtt2k"
          aspectRatio="auto"
        /> */}
        <Segment style={{ backgroundColor: '#f5f4ef' }}>
          <Image src="http://www.thesmoothiemania.com/imgs/mariannaShot.jpg" size="small" floated="left" />
          <p>
            My name is Marianna and my mission is to provide healthy products
            with a focus on taste and presentation. I am obsessed with health
            and wellness. Originally from South Korea, my life's work has always
            been finding ways to improve the conditions of my customers. By
            focusing on health and nutrition, I was able to create tasty
            smoothies for everyone to enjoy!
          </p>
          <p>
            During my time in South Korea, I became a licensed nutritionist from
            the Ministry of Health and have been working within the wellness
            industry. My vision is to try and improve the
            health of those around me for a better present, and more
            importantly, better future.
          </p>
          <p>
            Smoothie Mania strives to make all customers part of my family and I
            focus on making sure customers continually focus on their health. Of
            course - I know it's easier if the products are very tasty! Thank
            you for visiting Smoothie Mania and it a pleasure to always serve
            you.
          </p>
          <p>
            <Image
              style={{ marginLeft: 'auto' }}
              src="http://www.thesmoothiemania.com/imgs/fancySign.jpg"
              size="small"
            />
          </p>
        </Segment>
      </Fragment>
    );
  }
}

export default AboutPage;

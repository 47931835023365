import React, { Component } from 'react';

export default class Button extends Component {
  render() {
    return (
      <div>
        <button
          className="ui button"
          type={this.props.type}
          name={this.props.name}
          required={this.props.required}
          onClick={this.props.onClick}
        >
          Submit Message
        </button>
      </div>
    );
  }
}

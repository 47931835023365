import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import history from './history';
import AppBar from './Common/AppBar';
import MainPage from './Pages/MainPage';
import SmoothiePage from './Pages/SmoothiePage';
import ContactPage from './Pages/ContactPage';
import AboutPage from './Pages/AboutPage';
import Footer from './Common/Footer';
import SideDrawer from './SideDrawer/SideDrawer';
import Backdrop from './Backdrop/Backdrop';

class App extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return {
        sideDrawerOpen: !prevState.sideDrawerOpen,
      };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backDrop;

    if (this.state.sideDrawerOpen) {
      backDrop = <Backdrop click={this.backdropClickHandler}/>;
    }

    return (
      <div style={{ height: '100% !important' }}>
        <AppBar drawerClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} />
        {backDrop}
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={MainPage} />
            <Route path="/Smoothies" exact component={SmoothiePage} />
            <Route path="/Contact" exact component={ContactPage} />
            <Route path="/About" exact component={AboutPage} />
          </Switch>
        </Router>
        <Footer />
      </div>
    );
  }
}

export default App;

import React, { Component, Fragment } from 'react';
import { Header, Grid, Reveal, Image, Card } from 'semantic-ui-react';

class SmoothieList extends Component {
  render() {
    return (
      <Fragment>
        <Header as="h1" content="Delicious Smoothies" textAlign="center" />
        <Grid style={{ padding: '1rem' }} columns={4} doubling stackable>
          <Grid.Column>
            <Reveal animated="move">
              <Reveal.Content visible>
                <Image
                  rounded
                  label={{
                    as: 'a',
                    color: 'black',
                    content: 'Golden Berry Cheesecake',
                    ribbon: true,
                  }}
                  src="http://www.thesmoothiemania.com/imgs/goldenBerryCheesecake.jpg"
                  size="medium"
                />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card raised color="green">
                  <Image
                    style={{ padding: '0.5rem 0' }}
                    src="http://www.thesmoothiemania.com/imgs/goldenBerryCheesecake.jpg"
                    size="tiny"
                    centered
                  />
                  <Card.Content>
                    <Card.Header>Golden Berry Cheesecake</Card.Header>
                    <Card.Meta>Blueberry</Card.Meta>
                    <Card.Meta>Blackberry</Card.Meta>
                    <Card.Meta>Spinach</Card.Meta>
                    <Card.Meta>Kale</Card.Meta>
                    <Card.Meta>Roasted Oat</Card.Meta>
                    <Card.Description>
                      A smooth mix of anti-oxidant rich berries with a healthy
                      bunch of kale mixed with roasted oats. Great for arthritic
                      conditions!
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          <Grid.Column>
            <Reveal animated="move">
              <Reveal.Content visible>
                <Image
                  rounded
                  label={{
                    as: 'a',
                    color: 'black',
                    content: 'Green Coffee Cheesecake',
                    ribbon: true,
                  }}
                  src="http://www.thesmoothiemania.com/imgs/greenCoffeeCheesecake.jpg"
                  size="medium"
                />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card raised color="green">
                  <Image
                    style={{ padding: '0.5rem 0' }}
                    src="http://www.thesmoothiemania.com/imgs/greenCoffeeCheesecake.jpg"
                    size="tiny"
                    centered
                  />
                  <Card.Content>
                    <Card.Header>Green Coffee Cheesecake</Card.Header>
                    <Card.Meta>Green Tea</Card.Meta>
                    <Card.Meta>Spirulina</Card.Meta>
                    <Card.Meta>Coffee</Card.Meta>
                    <Card.Meta>Spinach</Card.Meta>
                    <Card.Meta>Kale</Card.Meta>
                    <Card.Meta>Cheesecake</Card.Meta>
                    <Card.Description>
                      Want a twist on your daily coffee and breakfast? Try this
                      delicious combination for a healthy boost of energy to get
                      you through your workday
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          <Grid.Column>
            <Reveal animated="move">
              <Reveal.Content visible>
                <Image
                  rounded
                  label={{
                    as: 'a',
                    color: 'black',
                    content: 'Mango Cheesecake',
                    ribbon: true,
                  }}
                  src="http://www.thesmoothiemania.com/imgs/mangoCheesecake.jpg"
                  size="medium"
                />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card raised color="green">
                  <Image
                    style={{ padding: '0.5rem 0' }}
                    src="http://www.thesmoothiemania.com/imgs/mangoCheesecake.jpg"
                    size="tiny"
                    centered
                  />
                  <Card.Content>
                    <Card.Header>Mango Cheesecake</Card.Header>
                    <Card.Meta>Mango</Card.Meta>
                    <Card.Meta>Cheesecake</Card.Meta>
                    <Card.Meta>Wheat Germ</Card.Meta>
                    <Card.Meta>Roast Oat</Card.Meta>
                    <Card.Meta>Whey Protein</Card.Meta>
                    <Card.Description>
                      Simple and refreshing with a balance of nutrients to give
                      you a tropical boost feel with an amazing sense of clean
                      fullness
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          <Grid.Column>
            <Reveal animated="move">
              <Reveal.Content visible>
                <Image
                  rounded
                  label={{
                    as: 'a',
                    color: 'black',
                    content: 'Pumpkin Coffee Cheesecake',
                    ribbon: true,
                  }}
                  src="http://www.thesmoothiemania.com/imgs/pumpkinCoffeeCheesecake.jpg"
                  size="medium"
                />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card raised color="green">
                  <Image
                    style={{ padding: '0.5rem 0' }}
                    src="http://www.thesmoothiemania.com/imgs/pumpkinCoffeeCheesecake.jpg"
                    size="tiny"
                    centered
                  />
                  <Card.Content>
                    <Card.Header>Pumpkin Coffee Cheesecake</Card.Header>
                    <Card.Meta>Pumpkin</Card.Meta>
                    <Card.Meta>Coffee</Card.Meta>
                    <Card.Meta>Cheesecake</Card.Meta>
                    <Card.Meta>Pecan</Card.Meta>
                    <Card.Meta>Cinnamon</Card.Meta>
                    <Card.Description>
                      Love 'Fall'? Then try this to give your energy a mix of
                      golden redish hues with a boost of energy. Very soothing
                      and fantastic!
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          <Grid.Column>
            <Reveal animated="move">
              <Reveal.Content visible>
                <Image
                  rounded
                  label={{
                    as: 'a',
                    color: 'black',
                    content: 'Quinoa Cheesecake',
                    ribbon: true,
                  }}
                  src="http://www.thesmoothiemania.com/imgs/quinoaCheesecake.jpg"
                  size="medium"
                />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card raised color="green">
                  <Image
                    style={{ padding: '0.5rem 0' }}
                    src="http://www.thesmoothiemania.com/imgs/quinoaCheesecake.jpg"
                    size="tiny"
                    centered
                  />
                  <Card.Content>
                    <Card.Header>Hempseed Protein Cheesecake</Card.Header>
                    <Card.Meta>Hempseed</Card.Meta>
                    <Card.Meta>Protein</Card.Meta>
                    <Card.Meta>Cheesecake</Card.Meta>
                    <Card.Description>
                      Tracking your macro nutrients? Hempseed is amazing for
                      your nine essential amino acids. This combination comes
                      packed with protein as well with the smoothness of
                      cheesecake to round the flavor.
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          <Grid.Column>
            <Reveal animated="move">
              <Reveal.Content visible>
                <Image
                  rounded
                  label={{
                    as: 'a',
                    color: 'black',
                    content: 'Strawberry',
                    ribbon: true,
                  }}
                  src="http://www.thesmoothiemania.com/imgs/strawberry.jpg"
                  size="medium"
                />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card raised color="green">
                  <Image
                    style={{ padding: '0.5rem 0' }}
                    src="http://www.thesmoothiemania.com/imgs/strawberry.jpg"
                    size="tiny"
                    centered
                  />
                  <Card.Content>
                    <Card.Header>Strawberry</Card.Header>
                    <Card.Meta>Strawberry..yup</Card.Meta>
                    <Card.Description>
                      Simply Strawberry...a customer favorite for hot summer
                      days
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          <Grid.Column>
            <Reveal animated="move">
              <Reveal.Content visible>
                <Image
                  rounded
                  label={{
                    as: 'a',
                    color: 'black',
                    content: 'Very Berry Special',
                    ribbon: true,
                  }}
                  src="http://www.thesmoothiemania.com/imgs/veryBerrySpecial.jpg"
                  size="medium"
                />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card raised color="green">
                  <Image
                    style={{ padding: '0.5rem 0' }}
                    src="http://www.thesmoothiemania.com/imgs/veryBerrySpecial.jpg"
                    size="tiny"
                    centered
                  />
                  <Card.Content>
                    <Card.Header>Very Berry Special</Card.Header>
                    <Card.Meta>Blueberry</Card.Meta>
                    <Card.Meta>Strawberry</Card.Meta>
                    <Card.Meta>Raspberry</Card.Meta>
                    <Card.Meta>Spinach</Card.Meta>
                    <Card.Meta>Kale</Card.Meta>
                    <Card.Meta>Wheat Germ</Card.Meta>
                    <Card.Description>
                      For a major anti-oxidant boost to stimulant the senses,
                      try this mix of berries with fresh spinach and kale. We
                      include wheat germ for the additional fiber, magnesium,
                      and zinc!
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          <Grid.Column>
            <Reveal animated="move">
              <Reveal.Content visible>
                <Image
                  rounded
                  label={{
                    as: 'a',
                    color: 'black',
                    content: 'Young Forever Green',
                    ribbon: true,
                  }}
                  src="http://www.thesmoothiemania.com/imgs/youngForeverGreen.jpg"
                  size="medium"
                />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card raised color="green">
                  <Image
                    style={{ padding: '0.5rem 0' }}
                    src="http://www.thesmoothiemania.com/imgs/youngForeverGreen.jpg"
                    size="tiny"
                    centered
                  />
                  <Card.Content>
                    <Card.Header>Young Forever Green</Card.Header>
                    <Card.Meta>Mango</Card.Meta>
                    <Card.Meta>Pineapple</Card.Meta>
                    <Card.Meta>Banana</Card.Meta>
                    <Card.Meta>Spinach</Card.Meta>
                    <Card.Meta>Kale</Card.Meta>
                    <Card.Meta>Spirulina</Card.Meta>
                    <Card.Description>
                      Fruits and Vegetables are key to maintaining a healthy
                      strong body. Try this mix of delicious fruits and fresh
                      vegetables to turn back the clock
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

export default SmoothieList;

import React, { Component, Fragment } from 'react';
import { Container, Grid, Header, Message, Icon } from 'semantic-ui-react';
import { Carousel } from 'react-bootstrap';
// import axios from 'axios';
import './MainPage.css';

class MainPage extends Component {
  // state = {
  //   yelpStatusUser: '',
  //   review: '',
  //   business: '',
  // };

  // componentDidMount() {
  //   const getUser = () => {
  //     return axios.get(
  //       'https://www.yelp.com/user_details?userid=uc5qvp-uGCFleOytHaEIVg',
  //     );
  //   };
  //   const getReview = () => {
  //     return axios.get(
  //       'https://www.yelp.com/biz/smoothie-mania-norfolk?hrid=pqNb9M0gdEsqi04ZQa2fVQ',
  //     );
  //   };

  //   const getBusiness = () => {
  //     return axios.get('https://www.yelp.com/biz/nk0UGv-0dCy-RzQtPzM3GQ');
  //   };
  //   axios
  //     .all([getUser(), getReview(), getBusiness()])
  //     .then((user, review, business) => {
  //       console.log(user);
  //       console.log(review);
  //       console.log(business);
  //       this.setState({
  //         yelpStatusUser: user,
  //         review: review,
  //         business: business,
  //       });
  //     });
  // }

  render() {
    return (
      <Fragment>
        <Container
          className="mainpage carousel"
          style={{
            height: '80%',
            width: '100%',
            margin: 0,
          }}
        >
          <Carousel>
            <Carousel.Item>
              <img
                className="carousel img"
                style={{
                  width: '100%',
                  height: '80vh',
                  maxWidth: '100%',
                  maxHeight: '80vh',
                }}
                src="http://www.thesmoothiemania.com/imgs/HomePageLanding.jpg"
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>Welcome to Smoothie Mania</h3>
                <p>
                  Our mission is to provide healthy, yet delicious options for the health-conscience
                  customer
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="carousel img"
                style={{
                  width: '100%',
                  height: '80vh',
                  maxWidth: '100%',
                  maxHeight: '80vh',
                }}
                src="http://www.thesmoothiemania.com/imgs/greenMainPage.jpg"
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>Welcome to Smoothie Mania</h3>
                <p>
                  Our mission is to provide healthy, yet delicious options for the health-conscience
                  customer
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="carousel img"
                style={{
                  width: '100%',
                  height: '80vh',
                  maxWidth: '100%',
                  maxHeight: '80vh',
                }}
                src="http://www.thesmoothiemania.com/imgs/juiceMainPage.jpg"
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>Welcome to Smoothie Mania</h3>
                <p>
                  Our mission is to provide healthy, yet delicious options for the health-conscience
                  customer
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="carousel img"
                style={{
                  width: '100%',
                  height: '80vh',
                  maxWidth: '100%',
                  maxHeight: '80vh',
                }}
                src="http://www.thesmoothiemania.com/imgs/mixedfruitMainPage.jpg"
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>Welcome to Smoothie Mania</h3>
                <p>
                  Our mission is to provide healthy, yet delicious options for the health-conscience
                  customer
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Container>
        <Message positive size="big">
          <Icon name="heart" />
          <Message.Header>WE ARE NOW HIRING</Message.Header>
          <p>
            Please message us on our contacts page, or <b>stop on by</b> with your interest!
          </p>
        </Message>
        <Header as="h2" textAlign="center">
          Testimonials
        </Header>
        <Grid container columns={3} stackable relaxed="very">
          <Grid.Column>
            <span
              className="yelp-review"
              data-review-id="0cDBFsx_FOhNlqqtaxck9w"
              data-hostname="www.yelp.com"
            >
              Read{' '}
              <a
                href="https://www.yelp.com/user_details?userid=bbjBpDyCL_5kHDSDTjaN1A"
                rel="nofollow noopener"
              >
                Mahogany W.
              </a>
              <a
                href="https://www.yelp.com/biz/smoothie-mania-norfolk?hrid=0cDBFsx_FOhNlqqtaxck9w"
                rel="nofollow noopener"
              >
                review
              </a>{' '}
              of{' '}
              <a href="https://www.yelp.com/biz/nk0UGv-0dCy-RzQtPzM3GQ" rel="nofollow noopener">
                Smoothie Mania
              </a>{' '}
              on{' '}
              <a href="https://www.yelp.com" rel="nofollow noopener">
                Yelp
              </a>
            </span>
          </Grid.Column>
          <Grid.Column>
            <span
              className="yelp-review"
              data-review-id="BhnsCUoLSLkUxNDHnHolPQ"
              data-hostname="www.yelp.com"
            >
              Read{' '}
              <a
                href="https://www.yelp.com/user_details?userid=d-IX3-0oC5amQd6TbEEuXg"
                rel="nofollow noopener"
              >
                Julie S.
              </a>
              <a
                href="https://www.yelp.com/biz/smoothie-mania-norfolk?hrid=BhnsCUoLSLkUxNDHnHolPQ"
                rel="nofollow noopener"
              >
                review
              </a>{' '}
              of{' '}
              <a href="https://www.yelp.com/biz/nk0UGv-0dCy-RzQtPzM3GQ" rel="nofollow noopener">
                Smoothie Mania
              </a>{' '}
              on{' '}
              <a href="https://www.yelp.com" rel="nofollow noopener">
                Yelp
              </a>
            </span>
          </Grid.Column>

          <Grid.Column>
            <span
              className="yelp-review"
              data-review-id="iCSNYLiJ4R6mIE8x3ffvKQ"
              data-hostname="www.yelp.com"
            >
              Read{' '}
              <a
                href="https://www.yelp.com/user_details?userid=qwQBNfv8Joqx77QGarra5Q"
                rel="nofollow noopener"
              >
                Ivey L.
              </a>
              <a
                href="https://www.yelp.com/biz/smoothie-mania-norfolk?hrid=iCSNYLiJ4R6mIE8x3ffvKQ"
                rel="nofollow noopener"
              >
                review
              </a>{' '}
              of{' '}
              <a href="https://www.yelp.com/biz/nk0UGv-0dCy-RzQtPzM3GQ" rel="nofollow noopener">
                Smoothie Mania
              </a>{' '}
              on{' '}
              <a href="https://www.yelp.com" rel="nofollow noopener">
                Yelp
              </a>
            </span>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

export default MainPage;

import React, { Component } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import * as emailjs from 'emailjs-com'; // why the emailjs-com in example?
import { Form } from 'semantic-ui-react'

import Input from './Input';
import TextArea from './TextArea';
import Button from './Button';

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    errors: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
  };

  // input change for controlled component
  handleInputChange = event => {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value,
    });
  };

  // validation
  validateEmail() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.name || this.state.name.length < 3) {
      errors.name = 'Minimum 3 characters please';
      formIsValid = false;
    }
    if (!this.state.subject || this.state.subject.length < 1) {
      errors.subject = 'Minimum 1 character for subject';
      formIsValid = false;
    }
    if (!this.state.message || this.state.message.length < 5) {
      errors.message = 'Minimu of 5 characters for your message';
      formIsValid = false;
    }
    if (!this.state.email || this.state.email.length < 3) {
      errors.email = 'Mimimum of 3 characters for email';
      formIsValid = false;
    }
    let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!pattern.test(this.state.email)) {
      errors.email = 'This is not a valid email';
      formIsValid = false;
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  }

  // sending message
  sentMessage = event => {
    event.preventDefault();

    if (!this.validateEmail()) {
      return;
    }

    var template_params = {
      from_name: this.state.name + '(' + this.state.email + ')',
      to_name: 'yymp.corp@gmail.com',
      subject: this.state.subject,
      message_html: this.state.message,
    };

    var service_id = 'default_service';
    var template_id = 'template_giWUNFUB';
    emailjs.send(service_id, template_id, template_params, 'user_SD9htVGpzaeVEAwqWjO1R').then(
      response => {
        toast.success('Message sent successful');
        console.log('SUCCESS!', response.status, response.text);
      },
      err => {
        toast.error(err);
        console.log(err);
      },
    );

    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };

  render() {
    return (
        <Form
          name={this.props.name}
          method={this.props.method}
          action={this.props.action}
        >
          <Input
            type="text"
            name="name"
            required="required"
            onChange={this.handleInputChange}
            value={this.state.name}
            error={this.state.errors.name}
          />
          <Input
            type="text"
            name="email"
            required="required"
            onChange={this.handleInputChange}
            value={this.state.email}
            error={this.state.errors.email}
          />
          <Input
            type="text"
            name="subject"
            required="required"
            onChange={this.handleInputChange}
            value={this.state.subject}
            error={this.state.errors.subject}
          />
          <TextArea
            name="message"
            required="required"
            rows="8"
            onChange={this.handleInputChange}
            value={this.state.message}
            error={this.state.errors.message}
          />
          <Button
            onClick={this.sentMessage}
            type="button"
            name="submit"
            required="required"
          />
          <ToastContainer autoClose={3000} />
        </Form>
    );
  }
}

export default ContactForm;
